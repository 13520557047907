import i18n from "@/plugins/i18n/i18n";
export type FormRule = (data: string) => true | string;

export const notEmpty: FormRule = (v: string) => {
  return v != null && v.length >= 1
    ? true
    : i18n.t("formErrors.required").toString();
};

export function stringLength(minLen: number, maxLen?: number): FormRule {
  return (v: string) => {
    if (v == null) return i18n.t("formErrors.required").toString();
    if (maxLen) {
      return v.length >= minLen && v.length <= maxLen
        ? true
        : `Must be between ${minLen} and ${maxLen} characters`;
    } else {
      return v.length >= minLen
        ? true
        : `Must be at least ${minLen} characters`;
    }
  };
}

export const isValidUrl: FormRule = (v: string) => {
  const re =
    /(?!.*\.\.)(https?:\/\/)?(www[0-9]*\.)?([-a-zA-Z0-9:%._+~#=]{1,256}\.)([a-zA-Z0-9]{1,24})(\b[-a-zA-Z0-9()!@:%_+.,~#?&/=]*)/;
  if (!re.test(v)) {
    return i18n.t("formErrors.isValidUrl").toString();
  }
  return true;
};

export const emailRules: FormRule = (v: string) => {
  return (
    // (v || "").match(/^[\w+.]+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/) !==
    (v || "").match(/.+@.+\..+/) !== null ||
    i18n.t("accounts.emailError").toString()
  );
};

export const emailNotRequiredRules: FormRule = (v: string) => {
  if (!v) return true;
  return (
    // (v || "").match(/^[\w+.]+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/) !==
    (v || "").match(/.+@.+\..+/) !== null ||
    i18n.t("accounts.emailError").toString()
  );
};

export const validateDateFormat: FormRule = (v: string) => {
  const invalidString = () =>
    i18n.t("formErrors.validateDateFormat").toString();

  function dateValid(day: number, month: number, year: number): true | string {
    const d = new Date(year, month - 1, day);

    return d.getMonth() === month - 1 &&
      d.getDate() === day &&
      d.getFullYear() === year
      ? true
      : invalidString();
  }

  // Assumes date format is "dd/mm/yyyy", "dd.mm.yyyy", "d/m/yyyy" OR "d.m.yyyy"
  if (/^\d\d\/\d\d\/\d\d\d\d$/.test(v)) {
    const [dd, mm, yyyy] = v.split("/").map((p) => parseInt(p, 10));
    return dateValid(dd, mm, yyyy);
  } else if (/^\d\d\.\d\d\.\d\d\d\d$/.test(v)) {
    const [dd, mm, yyyy] = v.split(".").map((p) => parseInt(p, 10));
    return dateValid(dd, mm, yyyy);
  } else if (/^\d\/\d\/\d\d\d\d$/.test(v)) {
    const [dd, mm, yyyy] = v.split("/").map((p) => parseInt(p, 10));
    return dateValid(dd, mm, yyyy);
  } else if (/^\d\.\d\.\d\d\d\d$/.test(v)) {
    const [dd, mm, yyyy] = v.split(".").map((p) => parseInt(p, 10));
    return dateValid(dd, mm, yyyy);
  }

  console.log("No date match");

  return invalidString();
};

export function validateAgeLimit(
  minDateMs: number,
  maxDateMs: number
): FormRule {
  return (v: string) => {
    const validateDateResult = validateDateFormat(v);
    if (typeof validateDateResult === "string") return validateDateResult;

    const seperator = v.indexOf("/") > -1 ? "/" : ".";
    const [dd, mm, yyyy] = v.split(seperator).map((p) => parseInt(p, 10));
    const dateMs = new Date(yyyy, mm - 1, dd).getTime();

    return dateMs <= minDateMs && dateMs >= maxDateMs
      ? true
      : i18n.t("formErrors.validateAgeLimit").toString();
  };
}

export function emailUnique(): FormRule {
  return (v) => v || "";
}
