var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-row',{staticClass:"broadcast-manager rounded",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{staticClass:"broadcast-manager__list",attrs:{"cols":"3"}},[_c('v-list',{staticClass:"pa-0"},[_vm._l((_vm.broadcasts),function(broadcast){return _c('v-list-item',{key:broadcast.id,class:_vm.selectedBroadcastId && _vm.selectedBroadcastId === broadcast.id
                ? 'selected'
                : '',on:{"click":function($event){return _vm.selectBroadcast(broadcast)}}},[_c('v-list-item-content',{staticClass:"py-5"},[_c('v-list-item-title',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"transform":"scale(1.6)"},attrs:{"color":_vm.broadcastStatusColor(broadcast)}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" "+_vm._s(broadcast.broadcastName)+" ")],1)],1)],1)}),(_vm.broadcastCreationEnabled)?_c('v-list-item',{class:"create-broadcast-cta" +
              (_vm.selectedBroadcast &&
              !_vm.selectedBroadcastId &&
              !_vm.selectedBroadcast.id
                ? ' selected'
                : ''),on:{"click":function($event){_vm.selectBroadcast(_vm.newBroadcast())}}},[_c('v-list-item-content',{staticClass:"d-flex flex-row py-5"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_c('v-list-item-title',{domProps:{"textContent":_vm._s('New broadcast')}})],1)])],1):_vm._e(),_c('div',{staticClass:"list-right-border"})],2)],1),_c('v-col',{staticClass:"background pa-12 px-12",attrs:{"cols":"9"}},[(_vm.selectedBroadcast)?_c('BroadcastEditor',{key:_vm.key,attrs:{"broadcast":_vm.selectedBroadcast},on:{"selectBroadcast":function($event){return _vm.updateSelectedBroadcast($event)},"updatedSelectedBroadcastId":function($event){return _vm.updateSelectedBroadcastId($event)},"clearBroadcast":function($event){_vm.selectBroadcast(_vm.newBroadcast())}}}):_vm._e()],1)],1)],1)]):_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"brand"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }