




























































































































































































































































































































































































































import copy from "fast-copy";
import {
  Broadcast,
  BroadcastActions,
  BroadcastImages,
  BroadcastStatus,
} from "@/components/Broadcast/broadcast/types";
import { stringLength, isValidUrl } from "@/helpers/form.validators";
import { apiService } from "@/services/api.service";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import UpMan from "@/services/api/upman.service";
import {
  UpManUseCase,
  UpManGeneralFile,
  UpManEntityPrefix,
} from "@/services/api/upman.service.types";
import { PINS_NAMESPACE } from "@/components/Pins";
import { Pin } from "@/components/Pins/types";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import { ConfigGetters, Features } from "@/config/types";
import {
  CoreConfigGetters,
  TenantConfig,
  User,
  CoreUserGetters,
} from "@/spect8-core-vue/src/types";

@Component({
  components: {
    ConfirmDialog,
    DateTimePicker,
  },
})
export default class BroadcastEditor extends Vue {
  @Prop({ required: true }) broadcast!: Broadcast;

  @Action(BroadcastActions.UpdateBroadcast)
  updateBroadcast!: (broadcast: Broadcast) => Promise<void>;
  @Action(BroadcastActions.RemoveBroadcast)
  removeBroadcast!: (broadcast: string) => Promise<void>;

  @Getter("userPins", { namespace: PINS_NAMESPACE })
  readonly userPins!: Pin[];
  @Getter("moderatorPins", { namespace: PINS_NAMESPACE })
  readonly moderatorPins!: Pin[];
  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;
  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  stringLength = stringLength;
  BroadcastStatus = BroadcastStatus;
  broadcastData: Broadcast = copy(this.broadcast);

  get videoSourceUrlError(): true | string {
    return isValidUrl(this.videoSourceInput);
  }

  videoSourceInput = "";
  formValid = true;
  modal = {
    deleteConfirmation: false,
  };

  pendingUploadCount = 0;
  pendingUploads: Record<
    keyof BroadcastImages,
    { type: UpManUseCase; file: File | null; error: boolean }
  > = {
    thumbnail: {
      type: UpManUseCase.Thumbnail,
      file: null,
      error: false,
    },
    logo: {
      type: UpManUseCase.Logo,
      file: null,
      error: false,
    },
    background: {
      type: UpManUseCase.FullHD,
      file: null,
      error: false,
    },
  };

  get broadcastDeletionEnabled(): boolean {
    return this.user !== null && !this.user.isOnlyBroadcastModerator();
  }

  async saveBroadcast() {
    let broadcast!: Broadcast;
    let save = true;
    let isNew = this.broadcastData.id.length === 0;

    // If the broadcast does not exist, we have to create it so we have an id to attach uploads too.
    if (!this.broadcastData.id) {
      broadcast = await apiService.saveBroadcast(this.broadcastData);
      await this.updateBroadcast(broadcast);

      if (this.pendingUploadCount) {
        this.broadcastData.id = broadcast.id;
        this.$emit("updatedSelectedBroadcastId", broadcast.id); // Update selected item in the broadcast manager
      } else {
        save = false;
      }
    }

    if (save) {
      const uploaded = await this.uploadFiles();
      if (!uploaded) return;
      broadcast = await apiService.saveBroadcast(this.broadcastData);
      await this.updateBroadcast(broadcast);
    }

    this.$toast.success(
      isNew
        ? this.$i18n.t("broadcastEditor.broadcastCreated")
        : this.$i18n.t("broadcastEditor.broadcastUpdated")
    );

    this.$emit("selectBroadcast", broadcast.id);
  }

  async deleteBroadcast() {
    await apiService.deleteBroadcast(this.broadcast.id);
    await this.removeBroadcast(this.broadcast.id);
    this.$emit("clearBroadcast");
  }

  async startBroadcast() {
    const broadcast = await apiService.startBroadcast(this.broadcastData.id);
    await this.updateBroadcast(broadcast);
    this.$emit("selectBroadcast", broadcast.id);
  }

  async endBroadcast() {
    const broadcast = await apiService.endBroadcast(this.broadcastData.id);
    await this.updateBroadcast(broadcast);
    this.$emit("selectBroadcast", broadcast.id);
  }

  addVideoSource(): void {
    if (!this.videoSourceInput.trim().length) return;

    this.broadcastData.videoSources.push(this.videoSourceInput);
    this.videoSourceInput = "";
  }

  deleteVideoSource(index: number): void {
    this.broadcastData.videoSources.splice(index, 1);
  }

  makePrimaryVideoSource(index: number) {
    const source = this.broadcastData.videoSources[index];
    this.broadcastData.videoSources.splice(index, 1);
    this.broadcastData.videoSources.unshift(source);
  }

  get buttonText(): string {
    if (!this.broadcastData.id) {
      return this.$t("broadcastEditor.createText").toString();
    }

    return this.$t("broadcastEditor.saveText").toString();
  }

  get saveButtonDisabled(): boolean {
    if (!this.formValid) {
      return true;
    }

    return !(this.hasChanged() || this.pendingUploadCount > 0);
  }

  get filteredUserPins(): Pin[] {
    return this.userPins.filter(this.filterByBroadcast);
  }

  get filteredModeratorPins(): Pin[] {
    return this.moderatorPins.filter(this.filterByBroadcast);
  }

  private filterByBroadcast(pin: Pin): boolean {
    return (
      pin.broadcasts.length == 0 || // 0 == Tenant Wide
      pin.broadcasts.some((broadcast) => broadcast.id === this.broadcastData.id)
    );
  }

  hasChanged(): boolean {
    return (
      JSON.stringify(this.broadcast) !== JSON.stringify(this.broadcastData)
    );
  }

  minDate(dates: (number | null)[] | null): number {
    const now = Date.now();
    if (dates) {
      // filter null and sort dates in ascending order
      const sortedDates = dates
        .filter((date): date is number => date !== null && !isNaN(date))
        .sort((a, b) => a - b);
      if (sortedDates.length > 0 && sortedDates[0] < now) {
        return sortedDates[0];
      }
    }
    return now;
  }

  async uploadFiles(): Promise<boolean> {
    const promises: Promise<UpManGeneralFile>[] = [];
    const imageKeyPromiseMap: Record<number, keyof BroadcastImages> = {};
    const uploads = Object.entries(this.pendingUploads);
    let success = true;

    uploads.forEach(([imageKey, { file, type }]) => {
      if (!file) return;

      imageKeyPromiseMap[promises.length] = imageKey as keyof BroadcastImages;
      promises.push(
        UpMan.uploadFile({
          tenantId: this.tenantConfig.tenantId,
          entityId: UpManEntityPrefix.Broadcast + this.broadcast.id,
          usecase: type,
          nfile: file,
          isDownloadable: true,
        })
      );
    });

    const settledPromises = await Promise.allSettled(promises);

    settledPromises.forEach((res, index) => {
      const imageKey = imageKeyPromiseMap[index];

      if (res.status === "rejected") {
        this.pendingUploads[imageKey].error = true;
        success = false;
        return;
      }

      if (res.status === "fulfilled") {
        this.broadcastData.images[imageKey] = res.value;
        this.pendingUploads[imageKey].file = null;
        return;
      }
    });

    if (!success) {
      Vue.$toast.error(this.$i18n.t("form.imageUploadFail"), {
        timeout: 2000,
      });
    }

    return success;
  }

  fileName({ filename, fileExtension }: UpManGeneralFile) {
    return filename + fileExtension;
  }

  @Watch("pendingUploads", { deep: true })
  countPendingUploads(): void {
    this.pendingUploadCount = Object.values(this.pendingUploads).filter(
      ({ file }) => file
    ).length;
  }
}
