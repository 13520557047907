









































































import { PINS_NAMESPACE } from "@/components/Pins";
import { PinsResponse } from "@/services/api/anonu.types";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Broadcast,
  BroadcastGetters,
  BroadcastStatus,
} from "../../Broadcast/broadcast/types";
import BroadcastEditor from "./BroadcastEditor.vue";
import { ConfigGetters, Features } from "@/config/types";
import {
  CoreConfigGetters,
  TenantConfig,
  User,
  CoreUserGetters,
} from "@/spect8-core-vue/src/types";

@Component({ components: { BroadcastEditor } })
export default class BroadcastManager extends Vue {
  @Action(`${PINS_NAMESPACE}/fetchPins`)
  fetchPins!: () => Promise<PinsResponse>;

  @Getter(`${PINS_NAMESPACE}/loaded`)
  readonly pinsLoaded!: boolean;
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(BroadcastGetters.Loading)
  readonly broadcastLoading!: boolean;
  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;

  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  selectedBroadcast: Broadcast | null = null;
  selectedBroadcastId: string | null = null;

  key = 0;

  created() {
    if (!this.broadcastLoading) {
      this.selectedBroadcast = this.broadcasts[0] ?? null;
      this.selectedBroadcastId = this.selectedBroadcast
        ? this.selectedBroadcast.id
        : null;
    }

    if (this.enabledFeatures.PINS && !this.pinsLoaded) {
      this.fetchPins();
    }
  }

  @Watch("broadcastLoading")
  broadcastsLoaded() {
    if (!this.broadcastLoading) {
      this.selectedBroadcast = this.broadcasts[0] ?? null;
    }
  }

  get filteredBroadcasts(): Broadcast[] {
    return this.broadcasts.filter(
      ({ status }) => status !== BroadcastStatus.DELETED
    );
  }

  // Only consider pinsLoaded when its feature is active
  get loaded(): boolean {
    return (
      !this.broadcastLoading && (!this.enabledFeatures.PINS || this.pinsLoaded)
    );
  }

  get broadcastCreationEnabled(): boolean {
    return this.user !== null && !this.user.isOnlyBroadcastModerator();
  }

  newBroadcast(): Broadcast {
    return {
      id: "",
      broadcastName: "",
      description: "",
      agenda: "",
      tenantId: this.tenantConfig.tenantId,
      startDate: null,
      endDate: null,
      deleteDate: null,
      images: { thumbnail: null, background: null, logo: null },
      status: BroadcastStatus.INACTIVE,
      videoSources: [],
    };
  }

  broadcastStatusColor({ status }: Broadcast): string {
    switch (status) {
      case BroadcastStatus.ACTIVE:
        return "success";
      case BroadcastStatus.INACTIVE:
        return "error";
      case BroadcastStatus.SCHEDULED:
        return "warning";
      default:
        return "gray";
    }
  }

  selectBroadcast(broadcast: Broadcast) {
    this.key++;

    this.selectedBroadcast = broadcast;
    this.selectedBroadcastId = broadcast.id;
  }

  updateSelectedBroadcast(broadcastId: string) {
    const broadcast = this.broadcasts.find(
      (broadcast) => broadcast.id === broadcastId
    );

    if (broadcast) {
      this.selectBroadcast(broadcast);
    }
  }

  // Updates selected id without triggering a broadcast state change
  updateSelectedBroadcastId(broadcastId: string) {
    this.selectedBroadcastId = broadcastId;
  }
}
